// i18next-extract-mark-ns-start recurring-payments
import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {SEO} from 'components/SEO';
import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

import recurring_payments from 'images/recurring_payments.png';
import modular from 'images/modular.svg';
import click1 from 'images/click1.svg';
import security from 'images/security.svg';
import {AnchorLink} from 'components/AnchorLink';
import {IndexBackground as _IndexBackground} from 'components/landings/IndexBackground';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {IndexContent} from 'components/landings/IndexContent';
import {LargeSectionHeader} from 'components/landings/LargeSectionHeader';
import {SignUpButton} from 'components/SignUpButton';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {IndexImage} from 'components/landings/IndexImage';
import {Background} from 'components/Background';
import {Content} from 'components/Content';
import {Card} from 'components/Card';
import CardsContainer from 'components/CardsContainer';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import {CompatibleWith, Logo} from 'components/monei-pay/CompatibleWith';
import visa from 'images/visa-logo-small.svg';
import masterCard from 'images/mastercard-logo-small.svg';
import discover from 'images/discover-logo-small.svg';
import jcb from 'images/jcb-logo-small.svg';
import dinersClub from 'images/dinersclub-logo-small.svg';
import applePay from 'images/applepay-logo-small.svg';
import googlePay from 'images/google_pay_color.svg';
import clickToPay from 'images/click_to_pay.svg';
import payByLinkFeature from 'images/pay-by-link-feature.svg';
import site_template from 'images/site_template.svg';
import {SupportLink} from 'components/links/Support';
import {LearnMoreLinks} from 'components/LearnMoreLinks';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import {FaqsSection} from 'components/FaqsSection';
import check from 'images/check_green.svg';
import {StyledDiv, StyledImg} from 'components/StyledSx';
import {ScreenSizes} from '../../../types/responsive';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const SubscriptionBackground = styled(Background)`
  &:after {
    content: none;
  }
  &:before {
    @media (max-width: 768px) {
      top: -100px;
    }
  }
`;

const IndexBackground = styled(_IndexBackground)`
  &:before {
    transform: rotate(-64deg) matrix(1, 0, 0, 1, 650, 0);
    height: 2700px;
    width: 2080px;
    left: 75%;
    @media (max-width: ${ScreenSizes.lg}) {
      display: none;
    }
  }
`;

const Section3Content = styled(Content)`
  margin-top: 150px;
  display: flex;
  align-items: center;
  @media (max-width: ${ScreenSizes.sm}) {
    margin-top: 0;
  }
  ul {
    margin-left: 0;

    li {
      list-style: none;
    }
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const logos: Logo[] = [
  {src: visa, height: '21px'},
  {src: masterCard, height: '28px'},
  {src: discover, height: '20px'},
  {src: jcb, height: '24px'},
  {src: dinersClub, height: '24px'},
  {src: applePay, height: '23px'},
  {src: googlePay, height: '23px'},
  {src: clickToPay, height: '21px'}
];

const CheckGreen = styled.img`
  margin: 4px 8px -2px 0;
  svg {
    fill: white;
  }
`;
const RecurringPayments: React.FC<PageProps> = (props) => {
  const {t} = useI18next();

  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  const faqs = [
    {
      header: t('In what countries is MONEI’s recurring payments system available?'),
      text: t(
        'MONEI Recurring Payments are available to merchants based in Spain and Andorra, for transactions made anywhere in the world.'
      ),
      content: (
        <Trans>
          MONEI Recurring Payments are available to merchants based in Spain and Andorra, for
          transactions made anywhere in the world.
        </Trans>
      )
    },
    {
      header: t('What is the maximum amount for recurring payments with MONEI?'),
      text: t(
        'The maximum amount for recurring payments is €2,500 per transaction. Contact support to request an increase.'
      ),
      content: (
        <Trans>
          The maximum amount for recurring payments is €2,500 per transaction.{' '}
          <SupportLink slug="/requests/new">Contact support</SupportLink> to request an increase.
        </Trans>
      )
    },
    {
      header: t('Is there a minimum amount for recurring payments with MONEI?'),
      text: t(
        'Depending on the payment processor, there is either no minimum or a minimum amount of €0.01 required to authorize recurring payments. If a charge is required to activate recurring payments, it’s automatically refunded unless the first charge is for the real payment amount. Recurring payments are considered active after the first real transaction.'
      ),
      content: (
        <Trans>
          Depending on the payment processor, there is either no minimum or a minimum amount of
          €0.01 required to authorize recurring payments. If a charge is required to activate
          recurring payments, it’s automatically refunded unless the first charge is for the real
          payment amount. Recurring payments are considered active after the first real transaction.
        </Trans>
      )
    },
    {
      header: t('Can I schedule recurring payments with MONEI?'),
      text: t(
        'Yes. Use MONEI’s Subscriptions API to automate recurring payments. You can create subscription names, set prices and billing cycles, and let customers manage their subscriptions.'
      ),
      content: (
        <Trans>
          Yes. Use MONEI’s{' '}
          <AnchorLink href="https://docs.monei.com/api/#tag/Subscriptions">
            Subscriptions API
          </AnchorLink>{' '}
          to automate recurring payments. You can create subscription names, set prices and billing
          cycles, and let customers manage their{' '}
          <InternalPageLink slug="features/subscriptions">subscriptions</InternalPageLink>.
        </Trans>
      )
    }
  ];
  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };

  return (
    <>
      <SEO
        path="subscriptions"
        title={t('Accept Recurring Payments: Increase Your Income')}
        description={t(
          'Save time and increase revenue with MONEI’s Recurring Payments. Accept credit cards, Google Pay, Apple Pay, and Click to Pay. Get started ››'
        )}
        schema={schemaFaqs}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile style={{paddingTop: '60px', paddingBottom: '30px'}}>
            <IndexContent maxWidth="660px">
              <LargeSectionHeader underline tagName="h1">
                <Trans>Save time and improve revenue with recurring payments</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Use MONEI’s Recurring Payments API to easily bill your customers for recurring
                payments and let them pay via their preferred payment methods including credit card,
                Apple Pay, Google Pay, and Click to Pay. Activate recurring payments via a unique
                payment link or integrate with your existing subscriptions infrastructure to
                streamline workflows, increase sales, and accept international payments.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              width="366px"
              top="27%"
              left="60%"
              src={recurring_payments}
              alt="MONEI recurring payments"
              title="MONEI recurring payments"
              hideLtMd
            />
          </Section>
          <StyledDiv sx={{marginBottom: '90px'}}>
            <CompatibleWith logos={logos} />
          </StyledDiv>
        </Content>
      </IndexBackground>
      <SubscriptionBackground>
        <Content style={{marginTop: '120px'}}>
          <div style={{textAlign: 'center', color: '#ffffff', marginBottom: '80px'}}>
            <Trans parent="h2">Securely process recurring payments with tokenization</Trans>
          </div>
          <CardsContainer style={{paddingBottom: '50px'}}>
            <Card padding="76px 32px 52px 32px">
              <CardImage
                style={{transform: 'translateY(-50%)', top: 0, margin: 0, width: '130px'}}
                src={modular}
                alt="Recurring payments"
                title="Recurring payments"
              />
              <CardWrapper>
                <Trans parent="h3">Step 1</Trans>
                <Trans parent="h5">
                  Integrate the{' '}
                  <AnchorLink href="https://docs.monei.com/api/#operation/payments_recurring">
                    Recurring Payment API
                  </AnchorLink>{' '}
                  with your custom-built website
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="76px 32px 52px 32px">
              <CardImage
                style={{transform: 'translateY(-40%)', top: 0, margin: 0, width: '105px'}}
                src={click1}
                alt="pay by link"
                title="pay by link"
              />
              <CardWrapper>
                <Trans parent="h3">Step 2</Trans>
                <Trans parent="h5">
                  Send a payment request via{' '}
                  <InternalPageLink slug="features/pay-by-link">Pay By Link</InternalPageLink> to
                  your customer
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="76px 32px 52px 32px">
              <CardImage
                style={{transform: 'translateY(-50%)', top: 0, margin: 0, width: '80px'}}
                src={security}
                alt="Tokenization"
                title="Tokenization"
              />
              <CardWrapper>
                <Trans parent="h3">Step 3</Trans>
                <Trans parent="h5">
                  Payment information is{' '}
                  <AnchorLink href="https://docs.monei.com/docs/guides/save-payment-method/">
                    tokenized
                  </AnchorLink>{' '}
                  for future transactions
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
          <SectionActions
            sx={{
              justifyContent: 'center',
              width: '100%',
              gap: '15px',
              display: 'flex',
              flexWrap: 'wrap'
            }}>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
            <ContactSalesButton />
          </SectionActions>
        </Content>
      </SubscriptionBackground>
      <Section3Content>
        <StyledImg
          src={payByLinkFeature}
          alt="Recurring payments phone"
          title="Recurring payments phone"
          sx={{
            width: {all: '550px', md: '450px'},
            marginBottom: '0',
            display: {all: 'block', sm: 'none'}
          }}
        />
        <StyledDiv sx={{marginTop: {all: '-80px', md: '0'}}}>
          <SectionHeader>
            <Trans>Increase income and improve operations with recurring payments</Trans>
          </SectionHeader>
          <List>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              Use recurring payments to charge customers on a recurring basis without having to
              chase them for payment details, thanks to{' '}
              <BlogLink slug="what-is-tokenization-and-its-benefits-for-e-commerce">
                tokenization
              </BlogLink>
              .
            </Trans>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              Grow internationally with{' '}
              <SupportLink slug="/articles/360017801897">multi-currency support</SupportLink>.
            </Trans>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              Integrate with your existing subscriptions infrastructure to tokenize payment
              information (or use MONEI’s{' '}
              <AnchorLink href="https://docs.monei.com/api/#tag/Subscriptions">
                Subscriptions API
              </AnchorLink>
              ).
            </Trans>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              Save money on transaction fees with{' '}
              <InternalPageLink slug="pricing">dynamic billing</InternalPageLink>.
            </Trans>
            <Trans parent="li">
              <CheckGreen src={check} width={16} height={16} />
              Get built-in payment security and compliance (
              <InternalPageLink slug="pci-dss">PCI DSS</InternalPageLink>,{' '}
              <BlogLink slug="psd2">PSD2</BlogLink>, <BlogLink slug="sca">SCA</BlogLink>, and{' '}
              <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                3D Secure
              </BlogLink>
              ).
            </Trans>
          </List>
          <SectionActions
            sx={{display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap'}}>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
            <ContactSalesButton />
          </SectionActions>
        </StyledDiv>
      </Section3Content>
      <Content>
        <Section column centered sx={{textAlign: 'center'}}>
          <SectionHeader underline>
            <Trans>Learn more about MONEI</Trans>
          </SectionHeader>
          <LearnMoreLinks />
        </Section>
      </Content>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <GradientBox
          wrapperStyle={{overflow: 'visible', marginTop: '100px'}}
          contentStyle={{maxWidth: '600px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Start accepting recurring payments today</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Speed up the billing process by saving payment information for future transactions.
              Skip the headache of chasing customers to authorize subsequent charges. Get started
              with MONEI Recurring Payments.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <GradientCtaImage
            sx={{
              maskImage: 'linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%)',
              bottom: '0',
              right: '70px',
              width: '25%'
            }}
            src={site_template}
          />
        </GradientBox>
      </Content>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Recurring payments FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default RecurringPayments;

const CardImage = styled.img`
  position: absolute;
`;

const CardWrapper = styled.div`
  max-width: 230px;
  h5 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 0.8rem;
  }
`;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "recurring-payments"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
